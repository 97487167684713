import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Button, Container, Typography } from "@material-ui/core";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "react-polyglot";
import Grid from "@material-ui/core/Grid";
import { useBaseData } from "../../Providers/Data/BaseDataProvider.jsx";
import useTitle from "../../hooks/useTitle.js";
import { useAuth } from "../../Providers/Auth/AuthProvider.jsx";
import BaseAccordion from "../../SharedComponents/BaseAccordion.jsx";
import useCompanyPublicId from "../../hooks/useCompanyPublicId.js";
import useFavicon from "../../hooks/useFavicon.js";
import SubjectCard from "./SharedComponents/SubjectCard.jsx";
import CompanyHeader from "./SharedComponents/CompanyHeader.jsx";

const useStyles = makeStyles((theme) => ({
  subjectsWrapper: {
    padding: "26px 0",
  },
  logo: {
    maxHeight: 150,
  },
  addedSubjects: {
    padding: "45px 0",
  },
  more: {
    backgroundColor: theme.palette.grey[300],
    marginBottom: theme.spacing(3),
  },
  expandableList: {
    padding: 0,
    flexDirection: "column",
  },
  logoutButton: {
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 20,
  },
}));

/** @returns {*}
 * @constructor
 */
const Subjects = () => {
  const { companyName, domain } = useCompanyPublicId();
  const classes = useStyles();
  const t = useTranslate();
  const { signOut, company2, user } = useAuth();
  const {
    availableSubjects,
    company,
    schoolNotFound,
    getAvailableSubjects,
    getAvailableSubjectsForCompany,
  } = useBaseData();
  const history = useHistory();

  const company_ = company || company2;

  useTitle("title.subjects", { school: (company_ && company_.name) || companyName || domain });

  useFavicon();

  useEffect(() => {
    if (user) {
      getAvailableSubjects().then();
    } else {
      getAvailableSubjectsForCompany(domain || companyName).then();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (schoolNotFound) {
    return <Redirect to="/" />;
  }

  const addedSubjects =
    (availableSubjects && availableSubjects.filter((subj) => subj.added === true || subj.added === undefined)) || [];
  const notAddedSubjects = (availableSubjects && availableSubjects.filter((subj) => subj.added === false)) || [];

  return (
    <Container maxWidth="lg">
      <CompanyHeader />
      <Box className={classes.subjectsWrapper} style={{ justifyContent: "center" }}>
        <Typography align="center" gutterBottom>
          {t("chooseSubject")}
        </Typography>
        <Grid container spacing={3} className={classes.addedSubjects}>
          {addedSubjects.map((subject) => (
            <SubjectCard
              key={subject.id}
              subject={subject}
              onClick={() => history.push(`${companyName ? "/" + companyName : ""}/subjects/${subject.id}`)}
            />
          ))}
        </Grid>
        {notAddedSubjects.length > 0 && (
          <Box marginTop="15px">
            <BaseAccordion
              defaultExpanded
              summary={<Typography>{t("more")}</Typography>}
              summaryClassName={classes.more}
              detailsClassName={classes.expandableList}
            >
              <Grid container spacing={3}>
                {notAddedSubjects.map((subject) => (
                  <SubjectCard
                    key={subject.id}
                    subject={subject}
                    onClick={() => history.push(`${companyName ? "/" + companyName : ""}/subjects/${subject.id}`)}
                  />
                ))}
              </Grid>
            </BaseAccordion>
          </Box>
        )}
        {user && (
          <Button className={classes.logoutButton} color="secondary" onClick={() => signOut()}>
            {t("logout")}
          </Button>
        )}
      </Box>
    </Container>
  );
};

export default Subjects;
